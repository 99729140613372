<template>
<div>
  <el-card class="box-card">
    <el-page-header @back="goBack" content="详情页面"></el-page-header>
    <el-descriptions title="用户基础信息" :column="4" border class="mt20">
      <el-descriptions-item label="用户名">{{ userinfo.nickname }}</el-descriptions-item>
      <el-descriptions-item label="手机号">{{ userinfo.mobile }}</el-descriptions-item>
      <el-descriptions-item label="真实姓名">{{ userinfo.realname }}</el-descriptions-item>
      <el-descriptions-item label="会员ID">{{ userinfo.userId }}</el-descriptions-item>
      <el-descriptions-item label="性别">{{userinfo.gender === 1 ? "男": "女"}}</el-descriptions-item>
      <el-descriptions-item label="积分">{{ userinfo.score }}</el-descriptions-item>
      <el-descriptions-item label="余额">{{ userinfo.money }}</el-descriptions-item>
      <el-descriptions-item label="佣金">{{ userinfo.commission }}</el-descriptions-item>
      <el-descriptions-item label="累计预约">{{ meetnum }}</el-descriptions-item>
      <el-descriptions-item label="累计消费">{{totalAmount}}</el-descriptions-item>
      <el-descriptions-item label="登录设备">{{ userinfo.brandmodel }}</el-descriptions-item>
      <el-descriptions-item label="推荐人"><el-tag size="small">{{ referrername }}</el-tag></el-descriptions-item>
      <el-descriptions-item label="家属" :contentStyle="{ 'text-align': 'left' }">
        <el-tag size="small" v-for="(familyitem, index) in userinfo.family" :key="index" v-if="familyitem.relation !== 0">{{ familyitem.name }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="联系地址" :contentStyle="{ 'text-align': 'right' }">
        <el-tag size="small" v-for="(addressitem, index) in userinfo.address" :key="index">{{ addressitem }}</el-tag>
      </el-descriptions-item>
    </el-descriptions>
  </el-card>
  <el-card class="box-card mt20">
    <div slot="header" class="clearfix">
      <el-row type="flex" justify="end">
        <el-col :span="8" class="lightgreen-box">
          <el-input
            v-model="searchorder"
            autocomplete="off"
            placeholder="请输入正确预约单号进行精确查找"
          ></el-input>
        </el-col>
        <el-col :span="2" class="orange-box ml20">
          <el-button type="primary" @click="serachloadItems()">点击搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="items" style="width: 100%; margin-bottom: 20px" row-key="_id" border >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="out_trade_no" label="预约单号"></el-table-column>
      <el-table-column prop="clinicname" label="门诊名称"></el-table-column>
      <el-table-column prop="doctor" label="问诊医生"></el-table-column>
      <el-table-column prop="meetperson" label="患者姓名"></el-table-column>
      <el-table-column prop="start" label="预约时间"></el-table-column>
      <el-table-column prop="status" label="订单状态">
        <template slot-scope="scope">
          {{ statustext(scope.row.status) }}
        </template>
      </el-table-column>
      <el-table-column label="是否签到">
        <template slot-scope="scope">
          {{ scope.row.signin === true?'已签到':'未签到'}}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="getDetails(scope.row)">详情</el-button>
          <el-button size="mini" v-if="scope.row.medicalrecord" @click="getmedicalrecord(scope.row.medicalrecord)">处方笺</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handlePageChange"
      @size-change="handlePageSizeChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 20, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>  
  </el-card>
  <el-dialog title="订单详情" :visible.sync="detailvisible">
    <el-descriptions direction="vertical" :column="4" border>
      <el-descriptions-item label="会员ID">{{ detail.userId }}</el-descriptions-item>
      <el-descriptions-item label="患者姓名">{{ detail.meetperson }}</el-descriptions-item>
      <el-descriptions-item label="就诊医生">{{ detail.doctor }}</el-descriptions-item>
      <el-descriptions-item label="预约手机">{{ detail.mobile }}</el-descriptions-item>
      <el-descriptions-item label="预约门诊">{{ detail.clinicname }}</el-descriptions-item>
      <el-descriptions-item label="下单时间">{{ changetime(detail.createdAt) }}</el-descriptions-item>
      <el-descriptions-item label="订单状态">{{ statustext(detail.status) }}</el-descriptions-item>
      <el-descriptions-item label="是否签到">{{ signistext(detail.signin) }}</el-descriptions-item>
    </el-descriptions>
    <span slot="footer" class="dialog-footer">
      <el-button @click="detailvisible = false">关 闭</el-button>
    </span>
  </el-dialog>
  <el-dialog title="处方签" :visible.sync="medicalvisible">
    <el-descriptions direction="vertical" :column="1" border>
      <el-descriptions-item label="主诉" v-if="medicalrecord.patientdescribe">
        {{ medicalrecord.patientdescribe }}
      </el-descriptions-item>
      <el-descriptions-item label="现病史" v-if="medicalrecord.historyofpresent">
        {{ medicalrecord.historyofpresent }}
      </el-descriptions-item>
      <el-descriptions-item label="既往史" v-if="medicalrecord.pasthistory">
        {{ medicalrecord.pasthistory }}
      </el-descriptions-item>
      <el-descriptions-item label="过敏史" v-if="medicalrecord.allergyhistory">
        {{ medicalrecord.allergyhistory }}
      </el-descriptions-item>
      <el-descriptions-item label="检查" v-if="medicalrecord.inspect">
        {{ medicalrecord.inspect }}
      </el-descriptions-item>
      <el-descriptions-item label="望闻问切" v-if="medicalrecord.auscultationandpalpation">
        {{ medicalrecord.auscultationandpalpation }}
      </el-descriptions-item>
      <el-descriptions-item label="诊断" v-if="medicalrecord.diagnosis">
        {{ medicalrecord.diagnosis }}
      </el-descriptions-item>
      <el-descriptions-item label="辨证" v-if="medicalrecord.dialectical">
        {{ medicalrecord.dialectical }}
      </el-descriptions-item>
      <el-descriptions-item label="医嘱" v-if="medicalrecord.exhort">
        {{ medicalrecord.exhort }}
      </el-descriptions-item>
      <el-descriptions-item label="项目" v-if="medicalrecord.consulting.length > 0">
        <el-tag
          :key="tag._id"
          type="success"
          effect="dark"
          v-for="tag in medicalrecord.consulting"
          :disable-transitions="false">
          {{tag.fullName}}*{{tag.defaultcount}}
        </el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="中药" v-if="medicalrecord.zhcnrecipe.length > 0 ">
        <el-tag
          :key="tag._id"
          type="success"
          effect="dark"
          v-for="tag in medicalrecord.zhcnrecipe"
          :disable-transitions="false">
          <div :key="productInfos._id" v-for="productInfos in tag.productInfos"> {{ productInfos.fullName }}*{{ productInfos.grammage }}</div>
        </el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="西药" v-if="medicalrecord.westrecipe.length > 0 ">
        <el-tag
          :key="tag._id"
          type="success"
          effect="dark"
          v-for="tag in medicalrecord.westrecipe"
          :disable-transitions="false">
          {{tag.fullName}}*{{tag.defaultcount}}
        </el-tag>
      </el-descriptions-item>
    </el-descriptions>
    <span slot="footer" class="dialog-footer">
      <el-button @click="medicalvisible = false">关 闭</el-button>
    </span>
  </el-dialog>
</div>  
</template>

<script>
export default {
  data() {
    return {
        id:'',
        openid:"",
        searchorder:"",
        detailvisible:false,
        medicalvisible:false,
        userinfo: {},
        referrername: "无",
        referrerid: "",
        referreropenid: "",
        currentPage: 1, // 当前页码
        pageSize: 5, // 每页数据量
        total: 0, // 总数据量
        totalAmount: 0,
        meetnum:0,
        items: [],
        detail:{},
        signintext:{
          false:"未签到",
          true:"已签到"
        },
        statusmap:{
          created:"待支付",
          paid:"已付款",
          cancelled:"已取消",
          finish:"已完成",
          expire:"已过期",
          refund:"已退款",
          refunding:"退款中",
          lapse:"已失效"
        },
        medicalrecord:{
          history: [],
          symptom: [],
          diagnosis: [],
          exhort: [],
          consulting: [],
          zhcnrecipe: [],
          westrecipe: [],
        },
    };
  },
  methods: {
    serachloadItems(){
      this.currentPage = 1
      this.pageSize = 5
      this.loadItems();
    },
    goBack() {
        this.$router.back();
    },
    statustext(text) {
      return this.statusmap[text] || "未知状态"
    },
    signistext(text) {
      return this.signintext[text] || "未知状态"
    },
    changetime(text) {
      const date = new Date(text);
      const options = { timeZone: 'Asia/Shanghai' };
      const formattedDate = date.toLocaleString('zh-CN', options);
      return formattedDate
    },
    getuserinfo() {
      this.$api.wxuser.userdetail(this.$route.query.id).then(res=>{
        this.userinfo = res.data.data
        this.totalAmount = res.data.totalAmount
        this.meetnum = res.data.meetnum
        if(res.data.data.referrer !== undefined && res.data.data.referrer !== "") {
          this.$api.wxuser.openiduserdetail(res.data.data.referrer).then(getreferrer =>{
            console.log(getreferrer)
            this.referrername = getreferrer.data.data.realname
          })
        }
      })
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loadItems();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.loadItems();
    },
    getDetails(data) {
      this.detailvisible = true
      this.detail = data
    },
    getmedicalrecord(data) {
      this.medicalvisible = true
      this.medicalrecord = data
      console.log(this.medicalrecord)
    },
    loadItems() {
      const data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        searchorder: this.searchorder,
        openid: this.openid
      }
      this.$api.meetorder.personorderlist(data).then((res) => {
        this.currentPage = res.data.pagination.currentPage;
        this.pageSize = res.data.pagination.pageSize;
        this.total = res.data.pagination.total;
        this.items = res.data.data;
      });
    },
  },
  mounted() {
    this.id = this.$route.query.id
    this.openid = this.$route.query.openid
    this.getuserinfo()
    this.loadItems()
  },
};
</script>

<style>
.mt20 {
  margin-top: 20px;
}
.ml20 {
  margin-left: 20px;
}
.el-tag.el-tag {
  margin: 3px;
}
</style>
